import React, { useState } from "react";
import {  useMutation } from "@apollo/client";
import {CREATE_ENTRY,LOAD_ENTRIES} from "GraphQL/Journals";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Tooltip } from '@mui/material';
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useTranslation } from "react-i18next";


export default function AddEntry(tasklistid) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const { t } = useTranslation("journal");
  const [isValidName, setIsValidName] = useState(false);

  const sendValue = (val) => {
    if (name !== "" && value !== "") {
      addjournalentry({
        variables: {
          name: name,
          content: value,
          followby : "",
          journalid: window.journalid,
        },
        refetchQueries: [
          {
            query: LOAD_ENTRIES,
            variables: { journal: window.journalid },
          },
        ],
      });
      setOpen(false);

    } else {
     setOpen(true);
    }
  };

  const close = () => {
    setOpen(false);
  };
 

  const handleClickOpen = () => {
    setValue("");
    setIsValidName(false);
    setOpen(true);
    setName("");

  };


  const handleNameChange = (event) => {
    const name = event.target.value;
    setName(name);
    validateNameAndValue(name, value);
  };

  const handleContentChange = (event) => {
    const value = event.target.value;
    setValue(value);
    validateNameAndValue(name, value);
  };

  const validateNameAndValue = (name, value) => {
    const isValid = name.trim().length > 2 && value.trim().length > 2;
    setIsValidName(isValid);
  };

  const [addjournalentry, { loading, error }] = useMutation(CREATE_ENTRY);

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const enabled = window.journalid !== undefined;


  return (
    <div style={{ position: 'relative', zIndex: 99999 }}>
      <Tooltip title={t("addentry.modal.title")}>
        <span>
          <Button disabled={!enabled} variant="light" onClick={handleClickOpen}><NoteAddIcon /></Button>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={close} fullWidth>
        <DialogTitle>      {t("addentry.modal.title")}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label={t("addentry.modal.creatorname")}
              value={name}
              onChange={handleNameChange}
              autoFocus
            />
             <TextField
              label={t("addentry.modal.content")}
              value={value}
              onChange={handleContentChange}
              multiline
              minRows={4}
              maxRows={4}
              
            />
           
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={close}>{t("addentry.modal.btncancel")}</Button>
          <Button size="small" disabled={!isValidName} variant="contained" onClick={() => { sendValue(); }}> {t("addentry.modal.btnsave")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );

 
}
