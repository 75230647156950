import { LOAD_JOURNALS } from "GraphQL/Journals";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import React from "react";
import GetEntriesTable from "./Mrt_Cell_edit";

import AddEntry from "./AddEntry";
import { Stack, Grid } from  "@mui/material";
//Translate
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "pictures/bundlogo.svg";
import Selector from "components/ListSelector";
import { useNavigate } from "react-router-dom";

window.journalid = "";
window.$name = "";

//**************************** DECLARE CONST *****************************************/
const GetJournals = (props) => {
  let filterState = props.filterState || ["CLOSED"];
  const { loading, error, data } = useQuery(LOAD_JOURNALS);
  const [selected, setSelected] = useState("");
  const { t } = useTranslation("journal");
  const navigate = useNavigate();

  useEffect(() => {
    if (selected.id !== undefined) {
      window.journalid = selected.id;
      window.$name = selected.name;
      document.title = "Journal : " + selected.name;
      navigate("/journal/" + selected.id);
    }
  }, [selected, navigate]);
  //**************************** DECLARE CONST *****************************************/

  if (loading) return <div>Loading...</div>;

  if (error) return `Error! ${error.message}`;

  let journals = data.allJournal.filter((j) => !filterState.includes(j.state));

  //Set selected from url if not already set
  if (props.id !== undefined) {
    let urlParam = journals.filter((j) => j.id === props.id);
    if (selected === "" && urlParam.length === 1) setSelected(urlParam[0]);
  }

  window.journalid = selected.id;
  window.$name = selected.name;

  if (data) {
    return (
      <div style={{ fontFamily: "Arial" }}>
        <Stack>
          <div>
            <Logo
              className="header"
              alt="Bund Logo"
              style={{
                width: 350,
                height: 80,
                padding: 5,
                display: "inline",
                float: "left",
              }}
            />
            <div style={{ float: "right", padding: 5, margin: 10 }}>
              {t("getjournals.headerdepartemenrt1")}
              <br></br>
              {t("getjournals.headerdepartemenrt2")}
              <br></br> <strong>{t("getjournals.headerarmee")}</strong>{" "}
            </div>
          </div>
          <div></div>
        </Stack>

        <Stack
          style={{ padding: 7 }}
          className="content"
          spacing={2}
          sx={{ maxWidth: "50ch" }}
        >
          <Grid container>
            <FormControl fullWidth>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Selector
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 1 }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: "150px", // Adjust the width as needed
                    }),
                  }}
                  label={t("getjournals.title")}
                  filterState={filterState}
                  onSetList={(op) => setSelected(op)}
                  value={!(selected === null) ? selected.id : ""}
                />
              
              <AddEntry journal={window.journalid} />
              </div>
            </FormControl>
          </Grid>
        </Stack>
        {selected.id && (
        <GetEntriesTable journal={selected.id} refresh={60} />
      )}
        
       
     
      </div>
    );
  }
};
export default GetJournals;
