import BundLogoImg from "pictures/Armee_Schild_pos_RGB.png";
import { useTranslation } from 'react-i18next';
import { Box, Typography } from "@mui/material";

export default function LandingPage() {
  const { t } = useTranslation();
  return (
    <div className="App" >
      <Typography margin={"1em"} variant="h1">{t('app_title')}</Typography>
      <Box component="img" src={BundLogoImg} sx={{
        maxHeight: { xs: 233 },
      }} />
    </div>
  );
}
