import React, { useMemo, useState, useCallback } from "react";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { MetroSpinner } from "react-spinners-kit";
import logo from "pictures/bundlogo.png";
import exportExcelFile from './exportExcel';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, MenuItem } from '@mui/material';
import { darken, lighten, useTheme } from '@mui/material';
//****************** IMPORT GQL ****************************/
import MODIFY_ENTRY from "GraphQL/ModifyTaskListEntry";
//import RESTORE from "../GraphQL/restore"; Remove restore function
//ADD NEW EDIT CELL
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useTranslation } from "react-i18next";

import Tooltip from '@mui/material/Tooltip';

import MODIFY_TCONTENT from "GraphQL/ModifyTaskListContent";

//************************ ICON ****************************/
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { useQuery, useMutation } from "@apollo/client";
import { LOAD_ENTRIEST } from "GraphQL/Tasklists";
import jsPDF from "jspdf";
import "jspdf-autotable";





const GetaskList = (tasklist) => {
  //**************************** DECLARE CONST *****************************************/
  //const [makeRestore, { errorR, dataR }] = useMutation(RESTORE);

  const [idcontent, setidcontent] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [modContent] = useMutation(MODIFY_TCONTENT);
  const [modTask, { dataj }] = useMutation(MODIFY_ENTRY);
  const { t, i18n } = useTranslation("tasklist");
  const theme = useTheme();


  let MRT_language = "";
  let dateResult = "";


  const baseBackgroundColor =
  theme.palette.mode === 'dark'
    ? 'rgba(240, 240, 240, 1)' 
    : 'rgba(255, 255, 255, 1)';

  // console.log("Language : ", i18n.language)
  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(LOAD_ENTRIEST, {
    variables: { tasklist: window.journalid },
    pollInterval: tasklist.refresh * 1000,
  });

  //**************************** DECLARE CONST *****************************************/
  if (dataj);
  //console.log(dataj)


  const handleContentClick = useCallback((row) => {
    setSelectedContent(row.original.content);
    setidcontent(row.original.id);
    setOpen(true);
  }, []);

  const handleSaveChanges = useCallback(() => {
    modContent({
      variables: {
        itemid: idcontent,
        journalid: window.journalid,
        content: selectedContent,
      },
    });
    setOpen(false);
  }, [idcontent, selectedContent]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleExport = () => {
    const dataforexecel = data.getTasklistEntryByTasklistId.map(entry => ({
      id: entry.id,
      content: entry.content,
      creationDate: formatDate(entry.createdAt),
      createdByname: entry.createdBy.name,
      priority: entry.priority,
      duedate: entry.dueDate,
      responsiblename: entry.responsible.name,
      recipientname: entry.recipient.name,
      status: entry.status,
      lastUpdateformated: formatDate(entry.lastUpdate),
    }));

    const columns = [
      { header: "Id", key: "id", width: 4 },
      { header: t("materialreacttable.creationdate"), key: "creationDate", width: 15 },
      { header: t("materialreacttable.content"), key: "content", width: 18, style: { alignment: { wrapText: true } } },
      { header: t("materialreacttable.creator"), key: "createdBy.name", width: 18 },
      { header: t("materialreacttable.priority"), key: "priority", width: 10 },
      { header: t("materialreacttable.duedate"), key: "duedate", width: 10 },
      { header: t("materialreacttable.responsible"), key: "responsible.name", width: 14, style: { alignment: { wrapText: true } } },
      { header: t("materialreacttable.recipient"), key: "recipient", width: 12, style: { alignment: { wrapText: true } } },
      { header: t("materialreacttable.status"), key: "status", width: 9 },
      { header: t("materialreacttable.lastupdate"), key: "lastUpdateformated", width: 15, style: { alignment: { wrapText: true } } },
    ];

    const file = window.$name ;
  console.log("Data", dataforexecel, "Columns", columns, "File", file);
    exportExcelFile(dataforexecel,columns, file, t);
  };

  //******************************** Export to PDF  *****************************************************************/
  const downloadPdf = (data) => {
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = logo;

    doc.setFontSize(7.5);
    doc.text(
      window.$name,
      doc.internal.pageSize.getWidth() / 2,
      35,
      null,
      null,
      "center"
    );

    doc.addImage(img, "png", 2, 2, 200, 50);

    doc.setFontSize(7.5);
    doc.text(t("getalltasklists.headerdepartemenrt1"), 600, 15);
    doc.text(t("getalltasklists.headerdepartemenrt2"), 600, 27);
    doc.text(t("getalltasklists.headerarmee"), 600, 39);

    const tableConfig = {
      theme: "striped",
      showHead: "everyPage",
      margin: { top: 60, right: 14, bottom: 50, left: 14 },
      cellWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 23 },
        1: { cellWidth: 100 },
        2: { cellWidth: 70 },
        3: { cellWidth: 150 },
        4: { cellWidth: 70 },
        5: { cellWidth: 60 },
        6: { cellWidth: 80 },
        7: { cellWidth: 70 },
        8: { cellWidth: 65 },
      },

      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data,
    };
    doc.autoTable(tableConfig);

    var i = "";
    var pageCount = doc.internal.getNumberOfPages();
    for (i = 0; i < pageCount; i++) {
      doc.setPage(i);
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
      doc.text(
        t("materialreacttable.page") + pageCurrent + "/" + pageCount,
        20,
        550
      );
    }

    doc.save(window.$name + ".pdf");
  };
  //** */****************************** Export to PDF  *****************************************************************/


  //**************************************** HANDLE SAVE ***********************************************************/
  //ADD HANDLESAVECELL
  const handleSaveCell = (cell, values) => {
    if (!data || !data.getTasklistEntryByTasklistId) {
      console.error("Data is undefined ", data, cell, values);
      return;
    }
    const { row, column } = cell;
    const entry = data.getTasklistEntryByTasklistId[row.index];
    const iddata = entry.id;
    //console.log("ID", data.getTasklistEntryByTasklistId[cell.row.index].id, " Value ", values);
    const formatted = new Date().toISOString().replace("Z", "");

    //ADD FOR CELL EDIT

    let contentdata = column.id === "content" ? values : entry.content;
    let createdBynamedata =
      column.id === "createdBy.name" ? values : entry.createdBy.name;
    let responsibledata =
      column.id === "responsible.name" ? values : entry.responsible?.name || "";
    let recipientdata =
      entry.recipient !== ""
        ? column.id === "recipient.name"
          ? values
          : entry.recipient?.name || ""
        : "";
    let prioritydata = column.id === "priority" ? values : entry.priority || "";

    let duedatedata =
      column.id === "due" ? values : entry.duedate ? null : null;

    let statusdata = column.id === "status" ? values : entry.status || "";

    //console.log("itemid",iddata,"journalid", window.journalid,contentdata,createdBynamedata,responsibledata,recipientdata,statusdata,"lastupdate", formatted,"dudedate",duedatedata)
    let vars = {
      itemid: iddata,
      journalid: tasklist.tasklist,
      content: contentdata,
      creator: createdBynamedata,
      responsible: responsibledata,
      recipient: recipientdata,
      priority: prioritydata,
      status: statusdata,
      lastupdate: formatted,
      due: duedatedata,
    };
    console.log("Vars", vars);
    if (values.duedate) vars.due = values.duedate;
    modTask({
      variables: vars,
      refetchQueries: [
        { query: LOAD_ENTRIEST, variables: { tasklist: window.journalid } },
      ],
    });
  };

  //**************************************  Date Difference  ********************************************************************/
  //Calculate the Due Date with actual DateTime
  const datedif = (duedate) => {
    if (duedate !== null) {
      var one_day = 1000 * 60 * 60 * 24;
      var present_date = new Date();
      var due_date = new Date(duedate);
      var Result =
        Math.round(due_date.getTime() - present_date.getTime()) / one_day;
      dateResult = Result.toFixed(0);
    } else {
      dateResult = null;
      duedate = null;
    }
  };
  //************************************  Date Difference ********************************************************************/

  const priorities = ["HIGH", "MEDIUM", "LOW"];

  const statuss = ["OPEN", "FINISHED", "ARCHIVED"];

  const priorityColumn = {
    accessorKey: "priority",
    header: (
      <Tooltip title={t("materialreacttable.priority")}>
        <span>{t("materialreacttable.priority")}</span>
      </Tooltip>
    ),
    enableClickToCopy: false,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      select: true,
      children: priorities.map((priority) => (
        <MenuItem key={priority} value={priority}>
          {priority}
        </MenuItem>
      )),
      onBlur: (event) => {
        handleSaveCell(cell, event.target.value, row, table);
      },
    }),
    size: 15,
    filterVariant: "multi-select",
    filterSelectOptions: priorities,
  };

  // ******************************************** Define Colums for Table *************************************************************
  const columns = useMemo(() => [
    {
      accessorKey: "id", //simple recommended way to define a column
      header: (
        <Tooltip title="id">
          <span>id</span>
        </Tooltip>
      ),
      size: 2,
      enableEditing: false,
      enableColumnActions: false,
      //  muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
    },
    {
      accessorKey: "createdAt", //simple recommended way to define a column
      header: (
        <Tooltip title={t("materialreacttable.creationdate")}>
          <span>{t("materialreacttable.creationdate")}</span>
        </Tooltip>
      ),
      enableEditing: false,
      size: 40,
      accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
      filterFn: "lessThanOrEqualTo",
      sortingFn: "datetime",
      Cell: ({ cell }) => cell.getValue()?.toLocaleString(), 
      Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      enableColumnFilter: false,
    },
    {
      accessorKey: 'createdBy.name',
      header: (
        <Tooltip title={t("materialreacttable.creator")}>
          <span>{t("materialreacttable.creator")}</span>
        </Tooltip>
      ),
      Cell: ({ cell }) => cell.getValue(),
      size: 30,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value, row, table);
        },
        placeholder: t("materialreacttable.creator") || '', 
      }),
    },
    {
      accessorKey: "content", //simple recommended way to define a column
      header: (
        <Tooltip title={t("materialreacttable.content")}>
          <span>{t("materialreacttable.content")}</span>
        </Tooltip>
      ),
      enableClickToCopy: false,
      enableEditing: false,
      Cell: ({ cell, row, column }) => (
        <span
          style={{ whiteSpace: "pre-line" }}
          onDoubleClick={() => handleContentClick(row)}
        >
          <div onDoubleClick={() => handleContentClick(row)}>
            {cell.renderValue()}
          </div>
        </span>
      ),
      muiEditTextFieldProps: ({ cell }) => ({
        variant: "outlined",
        multiline: true,
      }), 
      size: 60,
    },
    priorityColumn,
    {
      accessorKey: "due",
      header: (
        <Tooltip title={t("materialreacttable.duedate")}>
          <span>{t("materialreacttable.duedate")}</span>
        </Tooltip>
      ),
      size: 40,
      muiEditTextFieldProps: ({ cell, row, table }) => {
        const cellValue = cell.getValue();
        const isDefaultDate = !cellValue || new Date(cellValue).getFullYear() === 1970;
        const defaultDate = isDefaultDate ? new Date() : new Date(cellValue);

        return {
          type: "datetime-local",
          value: defaultDate.toISOString().slice(0, 16), // Utiliser value pour un composant contrôlé
          onChange: (event) => {
            const newValue = event.target.value;
            handleSaveCell(cell, newValue, row, table);
          },
        };
      },
      Cell: ({ cell }) => {
        const dateValue = cell.getValue();
        if (!dateValue || new Date(dateValue).getFullYear() === 1970) {
          return null;
        }

        const formattedDate = new Intl.DateTimeFormat('fr-CH', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }).format(new Date(dateValue)); // Format the date for display

        const dateDifference = datedif(dateValue);
        if (dateDifference) {
          dateDifference.toLocaleString();
        }
        return (
          <Box
            component="span"
            key={`${cell.id}-${cell.column.id}`} // Ensure unique key
            sx={(theme) => ({
              backgroundColor:
                dateResult <= 0
                  ? theme.palette.error.light
                  : dateResult <= 7
                    ? "#FFFACD"
                    : theme.palette.success.light,
              borderRadius: "0.25rem",
              color: "#000",
              maxWidth: "18ch",
              p: "0.25rem",
            })}
          >
            {formattedDate}
          </Box>
        );
      },
      accessorFn: (row) => new Date(row.due), // Convert to Date for sorting and filtering
    },
    {
      accessorKey: "responsible.name",
      header: (
        <Tooltip title={t("materialreacttable.responsible")}>
          <span>{t("materialreacttable.responsible")}</span>
        </Tooltip>
      ),
      size: 40,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value, row, table);
        },
        placeholder: t("materialreacttable.responsible") || '', 
      }),
    },
    {
      accessorKey: "recipient.name",
      header: (
        <Tooltip title={t("materialreacttable.recipient")}>
          <span>{t("materialreacttable.recipient")}</span>
        </Tooltip>
      ),
      size: 40,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value, row, table);
        },
        placeholder: t("materialreacttable.recipient") || '', 
      }),
    },
    {
      accessorKey: "status",
      header: (
        <Tooltip title={t("materialreacttable.status")}>
          <span>{t("materialreacttable.status")}</span>
        </Tooltip>
      ),
      enableClickToCopy: false,
      muiEditTextFieldProps: ({ cell, row, table }) => ({
        select: true,
        SelectProps: {
          native: false, // Use native select or not
        },
        children: statuss.map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        )),
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value, row, table);
        },
      }),
      size: 15,
      filterVariant: "multi-select",
      filterSelectOptions: statuss,
      Cell: ({ cell }) => {
        const theme = useTheme();
        const backgroundColor =
          cell.getValue() < "FINISH" && cell.getValue() < "ARCHIVED"
            ? theme.palette.error.light
            : cell.getValue() >= "OPEN"
              ? "#FFFACD"
              : theme.palette.success.light;

        return (
          <Box
            sx={{
              backgroundColor,
              borderRadius: "0.25rem",
              color: "#000",
              maxWidth: "55ch",
              p: "0.5rem",
            }}
          >
            {cell.getValue()?.toLocaleString?.("de-DE", {})}
          </Box>
        );
      },
    },
    {
      accessorKey: "lastUpdateformated", //simple recommended way to define a column
      header: (
        <Tooltip title={t("materialreacttable.lastupdate")}>
          <span>{t("materialreacttable.lastupdate")}</span>
        </Tooltip>
      ),
      accessorFn: (row) => new Date(row.lastUpdate), //convert to Date for sorting and filtering
      sortingFn: "datetime",
      size: 50,
      Cell: ({ cell }) => cell.getValue()?.toLocaleString(), //render Date as a string
      Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      enableColumnFilter: false,
    },
  ]);

  const tableData = useMemo(() => {
    if (data && data.getTasklistEntryByTasklistId) {
      return data.getTasklistEntryByTasklistId;
    }
    return [];
  }, [data]);


  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: { density: "compact", pagination: { pageIndex: 0, pageSize: 30 } },
    editDisplayMode: 'cell',
    enableEditing: true,
    localization: MRT_language,
    //SET EDIT
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell);
        console.log("editcell", cell, "column", column, "table", table);
      }
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          onClick={handleExport}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          size="small"
          tooltip={t("materialreacttable.ttexport2xlsx")}
        >
          {t("materialreacttable.btnexport2xlsx")}
        </Button>
        <Button
          onClick={(el) => {
            downloadPdf(
              data.getTasklistEntryByTasklistId.map((el) => [
                el.id,
                el.creationDate,
                el.createdBy.name,
                el.content,
                el.priority,
                el.duedate,
                el.responsible.name,
                el.recipientname,
                el.status,
                el.lastUpdate,
              ])
            );
          }}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          size="small"
        >
          {t("materialreacttable.btnexport2pdf")}
        </Button>
        <Button
          onClick={() => table.resetColumnFilters()}
          startIcon={<FilterAltOffIcon />}
          variant="contained"
          size="small"
        >
          {t("materialreacttable.tclearfilter")}
        </Button>
      </Box>
    ),
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.1),
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: lighten(baseBackgroundColor, 0.1),
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: darken(baseBackgroundColor, 0.2),
          },
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main,
    }),
  });


  if (!data || !data.getTasklistEntryByTasklistId || data.getTasklistEntryByTasklistId.length === 0) {
    return <div></div>; // or any other loading indicator
  }




  // ******************************************** Define Colums for Table *************************************************************
  function formatDate(string) {
    if (string !== null) {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(string).toLocaleDateString([], options);
    } else {
      string = null;
    }
  }

  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={40} color="black" loading={loading} />
      </div>
    );
  }

  if (error) return console.log("error");

  if (data) {
    console.log("Result Query array", data);


    return (
      <div>
      <Box sx={{ mt: 2, ml: 1, mr: 1 }}>
        <MaterialReactTable table={table} />
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{t("materialreacttable.modal.editcontent")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("modal.lblcontent")}
            value={selectedContent}
            onChange={(e) => setSelectedContent(e.target.value)}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            variant="filled"
            required
            size="small"
          />
          <Button
              onClick={() => setSelectedContent(t("modal.aeiou"))}
              variant="contained"
              sx={{ width: '20%' }}
              size="small"
            >
              {t("modal.btnaeiou")}
            </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("modal.cancel")}</Button>
          <Button onClick={handleSaveChanges} variant="contained">{t("modal.save")}</Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
};

export default GetaskList;
