import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ADD_OPERATION, GET_ALLOPERATION } from "GraphQL/Operations";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Tooltip } from '@mui/material';


export default function AddOperation(props) {
  let setOperationCallback =
    props.onSetOperation ||
    function (newop) {
      console.log(newop);
    };
  const [CreateOp, { loading, error }] = useMutation(ADD_OPERATION);
  const [open, setOpen] = useState(false);
  const [opName, setOpName] = useState("");
  const [isValidName, setIsValidName] = useState(false); // State to track if the name is valid
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { t } = useTranslation("admin");

  function close() {
    setOpen(false);
    setOpName("");
    setIsValidName(false);
  }

  const handleNameChange = (event) => {
    const name = event;
    setOpName(name);
    setIsValidName(name.trim().length > 2); // Update isValidName based on the input value */
    if (name.length < 3) return false;
    return true;
  };

  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && isValidName) {
      save();
    }
  };

  function save() {
    if (isValidName) {
      CreateOp({
        variables: {
          name: opName,
        },
        refetchQueries: [{ query: GET_ALLOPERATION }],
        onCompleted: (data) => {
          if (!error) {
            close();
            setOperationCallback(data.newOperation);
          }
        },
      });
    }
  }

  return (
    <div style={{ position: 'relative', zIndex: 99999 }}>
      <Tooltip title={t("dialog.newop.title")}>
        <span>
          <Button variant="light" onClick={handleClickOpen}><NoteAddIcon /></Button>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={close} fullWidth >
        <DialogTitle>     {t("dialog.newop.title")}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label={t("dialog.newop.lblopname")}
              placeholder={t("dialog.newop.lblopname")}
              value={opName}
              onChange={(e) => handleNameChange(e.target.value)}
              autoFocus
              size="small"
              margin="dense"
              variant="filled"
              onKeyDown={handleKeyDown}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={close}>{t("dialog.btncancel")}</Button>
          <Button size="small" disabled={!isValidName} variant="contained" onClick={() => { save(); }}> {t("dialog.btnsave")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
