import { useMutation } from "@apollo/client";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Tooltip } from '@mui/material';
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ADD_JOURNAL, GET_ALLJOURNAL } from "GraphQL/Journals";


export default function AddJournal(props) {
  let setCallback = props.onSet || function (newEntry) { console.log(newEntry) };
  const [CreateList, { error }] = useMutation(ADD_JOURNAL);
  const [open, setOpen] = useState(false);
  const [opName, setOpName] = useState("");
  const [isValidName, setIsValidName] = useState(false); 
  const { t } = useTranslation("admin");

  const handleClickOpen = () => {
    setOpen(true);
    setOpName("");
    setIsValidName(false);
  };

  const handleNameChange = (event) => {
    const name = event;
    setOpName(name);
    setIsValidName(name.trim().length > 2); // Update isValidName based on the input value */
    if (name.length < 3) return false;
    return true;
  };

  const close = () => {
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && isValidName) {
      save();
    }
  };

  const save = () => {
    if (isValidName) {
      CreateList({
        variables: { name: opName },
        refetchQueries: [{ query: GET_ALLJOURNAL }],
        onCompleted: (data) => {
          if (!error) {
            setOpen(false);
            setCallback(data.newEntry);
          }
        }
      });
    }
  };

  return (
    <div style={{ position: 'relative', zIndex: 99999 }}>
      <Tooltip title={t("dialog.newjournal.title")}>
        <span>
          <Button variant="light" onClick={handleClickOpen}><NoteAddIcon /></Button>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={close} fullWidth>
        <DialogTitle>{t("dialog.newjournal.title")}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label={t("dialog.newjournal.EnterJournalName")}
              placeholder={t("dialog.newjournal.EnterJournalName")}
              value={opName}
              onChange={(e) => handleNameChange(e.target.value)}
              autoFocus
              onKeyDown={handleKeyDown}
            />
           
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={close}>{t("dialog.btncancel")}</Button>
          <Button size="small" disabled={!isValidName} variant="contained" onClick={() => { save(); }}> {t("dialog.btnsave")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}