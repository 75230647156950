import ExcelJS from 'exceljs';


const exportExcelFile = (dataforexecel,columns, fileName, t) => {
  const workbook = new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet(fileName, {
    pageSetup: {
      horizontalCentered: true,
      paperSize: 9,
      orientation: "landscape",
      margins: {
        left: 0.3149606,
        right: 0.3149606,
        top: 0.3543307,
        bottom: 0.3543307,
        header: 0.3149606,
        footer: 0.3149606,
      },
    },
  });

  sheet.getRow(1).height = 30;
  sheet.pageSetup.printTitlesColumn = "1:1";
  sheet.autoFilter = {
    from: "A1",
    to: "J1",
  };

  // Define Border of Row 1 Header
  sheet.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
    sheet.getCell(cell.address).border = {
      top: { style: "thick", color: { argb: "FFFF0000" } },
      left: { style: "thick", color: { argb: "00000000" } },
      bottom: { style: "thick", color: { argb: "000000" } },
      right: { style: "thick", color: { argb: "FF00FF00" } },
    };
  });
  sheet.headerFooter.oddFooter = "&L Page &P of &N";

  const columnsStyle = { font: { name: "Arial Black", size: 10 } };

  // Define Headers Columns and set Background color
  const Columsexcel = [
    "A1",
    "B1",
    "C1",
    "D1",
    "E1",
    "F1",
    "G1",
    "H1",
    "I1",
    "J1",
  ];
  Columsexcel.forEach((cellAddress) => {
    const cell = sheet.getCell(cellAddress);
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "00000000" },
      bgColor: { argb: "96C8FB" },
    };
  });

  // Define font options
  sheet.getRow(1).font = {
    name: "Arial",
    family: 4,
    size: 10,
    bold: true,
    color: { argb: "FFFFFF" },
  };

  sheet.columns = columns;

  // Generate the Xlsx file
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${fileName}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

export default exportExcelFile;