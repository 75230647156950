import { gql } from "@apollo/client";

export const GET_ALLJOURNAL = gql`
  query AllJournal {
    list: allJournal {
      id
      name
      state
      operation {
        id
        name
        state
      }
    }
  }
`;

export const ADD_JOURNAL = gql`
  mutation CreateJournal($name: String!) {
    newEntry: addJournal(journal: { name: $name }) {
      id
      name
      state
      operation {
        id
        name
        state
      }
    }
  }
`;

export const MOD_JOURNAL = gql`
  mutation ModJ($j: JournalInputMod!) {
    modifyJournal(journal: $j) {
      id
    }
  }
`;

export const LOAD_ENTRIES = gql`
  query LOAD_JOURNAL($journal: ID!) {
    getJournalEntryByJournalId(journal: $journal) {
      content
      id
      createdAt
      createdBy {
        name
      }
      followup
      followupAt
      followupBy {
        name
      }
      lastUpdate
    }
  }
`;

export const LOAD_JOURNALS = gql`
  query LOADJOURNAL {
    allJournal {
      id
      name
      state
      operation {
        id
        name
      }
    }
  }
`;

export const CREATE_ENTRY = gql`
  mutation addjournalentry(
    $journalid: ID!
    $content: String!
    $name: String!
    $followby: String!
  ) {
    addJournalEntry(
      entry: {
        journal: $journalid
        createdBy: { name: $name }
        content: $content
        followupBy: { name: $followby }
      }
    ) {
      id
      journal {
        id
      }
      createdAt
      content
      createdBy {
        name
      }
      followup
      followupBy {
        name
      }
      tags
    }
  }
`; 

export const MODIFY_ENTRY = gql`
mutation modMaximalJournalentry(
  $itemid: ID!
  $journalid: ID!
  $content: String!
  $createdBy: String!
  $followup: String
  $followupAt: DateTime!
){
      modifyJournalEntry(entry:{
        id: $itemid
        journal: $journalid
        createdBy:
            {
            name: $createdBy
          }
        content: $content
        followup: $followup
        followupAt: $followupAt
      })
      {
        id
        journal{
          id
        }
        createdAt
        content
        createdBy{name}
        followup
        followupAt
        followupBy{name}
        lastUpdate
        tags
      }
    }
    `; 

  export const MODIFY_JEflwBy = gql`
  mutation modMJefollowBY(
    $itemid: ID!
    $journalid: ID!
    $followupBy: String!
  ) {
    modifyJournalEntry(
      entry: {
        id: $itemid
        journal: $journalid
        followupBy: { name: $followupBy }
      }
    ) {
      id
      journal {
        id
      }
      createdAt
      content
      createdBy {
        name
      }
      followup
      followupAt
      followupBy {
        name
      }
      lastUpdate
      tags
    }
  }
`;
