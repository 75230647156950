import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import CREATE_ENTRY from "GraphQL/AddMaxEntryFullConsequence";
import { FormGroup, Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { Dialog, Checkbox, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, NativeSelect, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from "react-i18next";
import  {LOAD_ENTRIESC} from "GraphQL/Consequences";


export default function AddEntry(journalid) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [kr, setkr] = useState("");
  const [source, setSource] = useState("");
  const [status, setStatus] = useState("OPEN");
  const { t } = useTranslation("consequence");

  const [state, setState] = React.useState({
    hrlcheck: false,
    ercheck: false,
    efcheck: false,
    ekcheck: false,
  });

  const { hrlcheck, ercheck, efcheck, ekcheck } = state;

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const sendValue = (e) => {
    addjournalentry({
      variables: {
        name: name,
        content: content,
        operationid: journalid.journalid,
        createdBy: name,
        source: source,
        hrl: hrlcheck,
        er: ercheck,
        kr: kr,
        ek: ekcheck,
        ef: efcheck,
      },
      refetchQueries: [
        { query: LOAD_ENTRIESC, variables: { operation: journalid.journalid } },
      ],
    });
   
    setContent("");
    setkr("");
    setState(ercheck === false);
    setState(efcheck === false);
    setState(ekcheck === false);
    setState(hrlcheck === false);
    setName("");
    setOpen(false);
    setSource("");
  };

  const handleClickOpen = () => {
    setContent("");
    setName("");
    setkr("");
    setStatus("");
    setSource("");
    setOpen(true);
  };

  const handleClose = () => {
    setContent("");
    setName("");
    setkr("");
    setOpen(false);
    setStatus("");
    setSource("");
  };

  const [addjournalentry, { loading, error, data }] = useMutation(CREATE_ENTRY, {
    refetchQueries: [{ query: LOAD_ENTRIESC, variables: { operation: window.journalid } }],
  });

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const isButtonDisabled = name.length < 1 || content.length < 1;

  const enabled = journalid.journalid !== undefined;

  return (
    <div>
      <Tooltip title={t("addentry.btnaddentry")}>
        <span>
          <Button disabled={!enabled} variant="light" onClick={handleClickOpen}><NoteAddIcon /></Button>
        </span>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>{t("addentry.btnaddentry")}</DialogTitle>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              autoFocus
              label={t("addentry.modal.creator")}
              onChange={(e) => setName(e.target.value)}
              required
              size="small"
              margin="dense"
              variant="filled"
            />
            <TextField
              label={t("addentry.modal.content")}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              multiline
              minRows={5}
              maxRows={6}
              radius="md"
              variant="filled"
              required
            />
            <Button
              onClick={() => setContent(t("addentry.modal.aeiou"))}
              variant="contained"
              sx={{ width: '20%' }}
            >
              {t("addentry.modal.btnaeiou")}
            </Button>
            <TextField
              label={t("addentry.modal.source")}
              value={source}
              onChange={(e) => setSource(e.target.value)}
              variant="filled"
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox
                checked={ercheck}
                onChange={handleChange}
                name="ercheck"
                color="primary" />}
                label={t("addentry.modal.er")} />
              <FormControlLabel control={<Checkbox
                checked={hrlcheck}
                onChange={handleChange}
                name="hrlcheck"
                color="primary" />}
                label={t("addentry.modal.hrl")} />
              <FormControlLabel control={<Checkbox
                  checked={ekcheck}
                  onChange={handleChange}
                  name="ekcheck"
                  color="primary" />}
                label={t("addentry.modal.ek")} />
                  <FormControlLabel control={<Checkbox
                      checked={efcheck}
                      onChange={handleChange}
                      name="efcheck"
                      color="primary" />}
                label={t("addentry.modal.ef")} />
            </FormGroup>
            <FormGroup>
              <TextField
                 margin="dense"
                label={t("addentry.modal.kr")}
                type="KR"
                value={kr}
                onChange={(e) => setkr(e.target.value)}
              />        
            </FormGroup>
            < DialogActions>
              <Button
                onClick={(e) => {
                  handleClose(e);
                }}
                variant="contained"
              >
                {t("addentry.modal.btncancel")}
              </Button>
              <Button
                type="submit"
                onClick={() => { sendValue(); }}
                disabled={isButtonDisabled}
                variant="contained"
              >
                {t("addentry.modal.btnsave")}
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
