import { gql } from "@apollo/client";

export const LOAD_ENTRIESC = gql`
  query LOAD_CONSEQUENCELIST($operation: ID!) {
    getConsequenceEntryByOperationId(operation: $operation) {
      operation {
        id
        name
        state
      }
      id
      createdAt
      content
      createdBy {
        name
      }
      source
      hrl
      er
      kr
      ek
      ef
      lastUpdate
    }
  }
`;

export const MODIFY_CHECK_ENTRY = gql`
  mutation modifyCheckEntry(
    $id: ID!
    $operation: ID!
    $hrl: Boolean
    $er: Boolean
    $ek: Boolean
    $ef: Boolean
  ) {
    modifyConsequenceEntry(
      entry: {
        operation: $operation
        id: $id
        hrl: $hrl
        er: $er
        ek: $ek
        ef: $ef
      }
    ) {
      operation {
        id
      }
      id
      hrl
      er
      kr
      ek
      ef
    }
  }
`;

export const MODIFY_ENTRY = gql`
mutation modifyConsequenceEntry(
  $operation : ID!
  $id: ID!
  $createdBy : String!
  $sourcedata : String
  $kr: String
){
  modifyConsequenceEntry(entry:{
        operation: $operation
        id: $id
        source: $sourcedata
        createdBy:{name: $createdBy}
        kr:$kr
      })
      {
      operation{
        id
      }
      id
      createdAt
      content
      createdBy{name}
      hrl
      er
      kr
      ek
      ef
      source
      lastUpdate
  }
    }
      `;

export const MODIFY_CCONTENT = gql`
      mutation modifyConsequenceContent(
        $operation : ID!
        $id: ID!
        $content: String
      ){
        modifyConsequenceEntry(entry:{
              operation: $operation
              id: $id
              content: $content
            })
            {
            operation{
              id
            }
            id
            createdAt
            content
            createdBy{name}
            hrl
            er
            kr
            ek
            ef
            source
            lastUpdate
        }
          }
            `;