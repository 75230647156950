import React, { useMemo, useState, useCallback } from "react";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';

import { MetroSpinner } from "react-spinners-kit";
//Import Material React Table Translations
//import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";

//****************** IMPORT GQL ****************************/
import {MODIFY_ENTRY} from "GraphQL/Consequences";
import MODIFY_CONTENT from "GraphQL/ModifyConsequencecontent";

//****************** IMPORT GQL ****************************/

//************************ ICON ****************************/
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
//import RestoreIcon from "@mui/icons-material/Restore";
//************************ ICON ****************************/
import logo from "pictures/bundlogo.png";
import { useQuery,  useMutation } from "@apollo/client";
import Tooltip from '@mui/material/Tooltip';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Dialog, Checkbox, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, NativeSelect, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { darken, lighten, useTheme } from '@mui/material';
import { useTranslation } from "react-i18next";
import  {LOAD_ENTRIESC} from "GraphQL/Consequences";
import  {MODIFY_CHECK_ENTRY} from "GraphQL/Consequences";



const GetEntriesTable = (operation, operationName) => {
  //**************************** DECLARE CONST *****************************************/
  const [modConse, { errorm, dataj }] = useMutation(MODIFY_ENTRY);
  const [modCheck] = useMutation(MODIFY_CHECK_ENTRY);
  const [modContent] = useMutation(MODIFY_CONTENT);
  const [idcontent, setidcontent] = useState(null);
  const [selectedContent, setSelectedContent] = useState("");
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation("consequence");
  const ExcelJS = require("exceljs");
  const [state] = React.useState({
    hrlcheck: "",
    ercheck: "",
    efcheck: "",
    ekcheck: "",
  });

  let MRT_language = "";
  const theme = useTheme();

  const baseBackgroundColor =
    theme.palette.mode === 'dark'
      ? 'rgba(240, 240, 240, 1)'
      : 'rgba(255, 255, 255, 1)';

  //console.log("Language : ", i18n.language);

  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(LOAD_ENTRIESC, {
    variables: { operation: operation.operation },
  });



  //**************************** DECLARE CONST *****************************************/

  //******************************** Export to PDF  *****************************************************************/
  const downloadPdf = (data) => {
    console.log(data);
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = logo;
    //ADD TITLE
    // doc.addFont('Arial', 'Arial', 'normal');
    //doc.setFont("Arial");
    doc.setFontSize(14);
    doc.text(
      window.$name,
      doc.internal.pageSize.getWidth() / 2,
      35,
      null,
      null,
      "center"
    );
    //ADD BUNDLOGO
    doc.addImage(img, "png", 2, 2, 200, 50);
    //ADD BUNDTEXT
    doc.setFontSize(10);
    doc.text(t("getallconsequences.headerdepartemenrt1"), 600, 15);
    doc.text(t("getallconsequences.headerdepartemenrt2"), 600, 27);
    doc.text(t("getallconsequences.headerarmee"), 600, 39);

    // Modify each inner array to change boolean values to "yes" and "no" for the "HRL" field at index 5
    const modifiedData = data.map((rowArray) => {
      const modifiedRowArray = [...rowArray];
      const hrlValue = modifiedRowArray[5]; // Get the value of "HRL" at index 5
      const erValue = modifiedRowArray[6]; // Get the value of "HRL" at index 6
      const ekValue = modifiedRowArray[8]; // Get the value of "HRL" at index 8
      const efValue = modifiedRowArray[9]; // Get the value of "HRL" at index 9
      // Change the "HRL" value to "yes" or "no" based on its boolean value
      modifiedRowArray[5] = hrlValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");
      modifiedRowArray[6] = erValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");
      modifiedRowArray[8] = ekValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");
      modifiedRowArray[9] = efValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");

      return modifiedRowArray;
    });

    //ADD DATA TABLE
    doc.autoTable({
      theme: "striped",
      showHead: "everyPage",
      margin: { top: 60, right: 14, bottom: 50, left: 14 },
      cellWidth: "wrap",
      // columnWidth: "wrap",
      columnStyles: {
        0: {
          cellWidth: 30,
        },
        1: {
          cellWidth: 70,
        },
        2: {
          cellWidth: 70,
        },
        3: {
          cellWidth: 70,
        },
        4: {
          cellWidth: 70,
        },
        5: {
          cellWidth: 30,
        },
        6: {
          cellWidth: 70,
        },
        7: {
          cellWidth: 150,
        },
        8: {
          cellWidth: 70,
        },
        9: {
          cellWidth: 70,
        },
      },

      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: modifiedData,
    });
    console.log(modifiedData);
    //FOOTER
    var i = "";
    var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
    for (i = 0; i < pageCount; i++) {
      doc.setPage(i);
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
      doc.text(t("page") + pageCurrent + "/" + pageCount, 20, 580);
    }
    doc.save(window.$name + ".pdf");
  };
  //******************************** Export to PDF  *****************************************************************/



  
  const handleSaveCell = (cell, values) => {
    if (!data || !data.getConsequenceEntryByOperationId) {
      console.error("Data is undefined ", data, cell, values);
      return;
    }
    const { row, column } = cell;
    const entry = data.getConsequenceEntryByOperationId[row.index];
    const iddata = entry.id;
    let contentdata = column.id === "content" ? values : entry.content;
    let createdBynamedata =   column.id === "createdBy.name" ? values : entry.createdBy.name;    
    let sourcedata = column.id === "source" ? values : entry.source;
    let krdata = column.id === "kr" ? values : entry.kr;
   

    let vars = {
      id: iddata,
      operation: operation.operation,
      content: contentdata,
      createdBy: createdBynamedata,
      sourcedata: sourcedata,
      kr: krdata,    
    };

    modConse({ variables: vars ,
      refetchQueries: [
        { query: LOAD_ENTRIESC, variables: {  operation: operation.operation, } },
      ],
    });
  };

  //**************************** HANDLE MODAL *****************************************/
  const handleContentClick = useCallback((row) => {
    setSelectedContent(row.original.content);
    setidcontent(row.original.id);
    setOpen(true);
  }, []);

  const handleSaveChanges = () => {
    modContent({
      variables: {
        id: idcontent,
        operation: window.journalid,
        content: selectedContent,
      },
    });
    setOpen(false);
  };
  //**************************** HANDLE MODAL *****************************************/

  if (dataj) {
  }
  // ****************************** DBL CLICK EDIT / SAVE ***********************************************************/
  const handleCheckboxChange = useCallback((row, columnId) => {
    const newValue = !row.original[columnId];
    modCheck({
      variables: {
        id: row.original.id,
        operation: window.journalid,
        [columnId]: newValue,
      },
      refetchQueries: [{ query: LOAD_ENTRIESC }],
    });
  }, [modCheck]);

  

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: (
          <Tooltip title="id">
            <span>id</span>
          </Tooltip>
        ),
        size: 2,
        enableEditing: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "createdAt", 
        header: (
          <Tooltip title={t("tablelibrary.creationdate")}>
            <span>{t("tablelibrary.creationdate")}</span>
          </Tooltip>
        ),
        enableEditing: false,
        enableColumnFilter: false,
        accessorFn: (row) => new Date(row.createdAt), 
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleString(), 
        Header: ({ column }) => <em>{column.columnDef.header}</em>, 
        size: 1,
      },
      {
        accessorKey: 'createdBy.name',
        header: (
          <Tooltip title={t("tablelibrary.creator")}>
            <span>{t("tablelibrary.creator")}</span>
          </Tooltip>
        ),
        Cell: ({ cell }) => cell.getValue(),
        size: 30,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value, row, table);
          },
          placeholder: t("tablelibrary.creator") || '',
        }),
      },
      {
        accessorKey: "content", 
        header: (
          <Tooltip title={t("tablelibrary.content")}>
            <span>{t("tablelibrary.content")}</span>
          </Tooltip>
        ),
        style: { whiteSpace: "normal", wordWrap: "break-word" }, 
        enableClickToCopy: false,
        enableEditing: false,
        Cell: ({ cell, row, column }) => (
          <span
            style={{ whiteSpace: "pre-line" }}
            onDoubleClick={() => handleContentClick(row)}
          >
            <div onDoubleClick={() => handleContentClick(row)}>
              {cell.renderValue()}
            </div>
          </span>
        ),
        muiEditTextFieldProps: ({ cell }) => ({
          variant: "outlined",
          multiline: true,
          placeholder: t("tablelibrary.content") || '',
        }), 
        size: 80,
      },
      {
        accessorKey: "source", 
        header: (
          <Tooltip title={t("tablelibrary.source")}>
            <span>{t("tablelibrary.source")}</span>
          </Tooltip>
        ),
        Cell: ({ cell }) => cell.getValue(),
        size: 30,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value, row, table);
          },
          placeholder: t("tablelibrary.source") || '',
        }),
      },
      {
        accessorKey: "hrl", //simple recommended way to define a column
        header: (
          <Tooltip title={t("tablelibrary.hrl")}>
            <span>{t("tablelibrary.hrl")}</span>
          </Tooltip>
        ),
        enableEditing: false,
        size: 1,
        type: Checkbox,
        Cell: ({ cell, row }) => (
          <Checkbox
            checked={cell.getValue() === true}
            color={cell.getValue() === true ? "primary" : "secondary"}
            size="md"
            onDoubleClick={() => handleCheckboxChange(row, "hrl")}
          />
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },
      {
        accessorKey: "er", 
        header: (
          <Tooltip title={t("tablelibrary.er")}>
            <span>{t("tablelibrary.er")}</span>
          </Tooltip>
        ),
        enableEditing: false,
        size: 0.5,
        type: Checkbox,
        Cell: ({ cell, row }) => (
          <Checkbox
            checked={cell.getValue() === true}
            color={cell.getValue() === true ? "primary" : "secondary"}
            size="md"
            onDoubleClick={() => handleCheckboxChange(row, "er")}
          />
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },
      {
        accessorKey: "kr", 
        header: (
          <Tooltip title={t("tablelibrary.kr")}>
            <span>{t("tablelibrary.kr")}</span>
          </Tooltip>
        ),
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value, row, table);
          },
          placeholder: t("tablelibrary.kr") || '',
        }),
        size: 0.5,
      },
      {
        accessorKey: "ek", 
        header: (
          <Tooltip title={t("tablelibrary.ek")}>
            <span>{t("tablelibrary.ek")}</span>
          </Tooltip>
        ),
        enableEditing: false,
        size: 1,
        type: Checkbox,
        Cell: ({ cell, row }) => (
          <Checkbox
            checked={cell.getValue() === true}
            color={cell.getValue() === true ? "primary" : "secondary"}
            size="md"
            onDoubleClick={() => handleCheckboxChange(row, "ek")}
          />
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },

      {
        accessorKey: "ef", 
        header: (
          <Tooltip title={t("tablelibrary.ef")}>
            <span>{t("tablelibrary.ef")}</span>
          </Tooltip>
        ),
        enableEditing: false,
        size: 1,
        accessorFn: (originalRow) => (originalRow.ef ? true : false),
        type: Checkbox,
        Cell: ({ cell, row }) => (
          <Checkbox
            checked={cell.getValue() === true}
            color={cell.getValue() === true ? "primary" : "secondary"}
            size="md"
            onDoubleClick={() => handleCheckboxChange(row, "ef")}
          />
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },
      {
        accessorKey: "lastUpdate", 
        header: (
          <Tooltip title={t("tablelibrary.lastupdate")}>
            <span>{t("tablelibrary.lastupdate")}</span>
          </Tooltip>
        ),
        enableEditing: false,
        enableColumnFilter: false,
        accessorFn: (row) => new Date(row.createdAt), 
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleString(), 
        Header: ({ column }) => <em>{column.columnDef.header}</em>, 
        size: 1, 
      },
    ],
    [t, handleSaveCell]
  );
  // ******************************************** Define Colums for Table *************************************************************

  const tableData = useMemo(() => {
    if (data && data.getConsequenceEntryByOperationId) {
      return data.getConsequenceEntryByOperationId;
    }
    return [];
  }, [data]);


  const table = useMaterialReactTable({
    columns,
    data: tableData,
    initialState: { density: "compact", pagination: { pageIndex: 0, pageSize: 30 } },
    editDisplayMode: 'cell',
    enableEditing: true,
    localization: MRT_language,
     //SET EDIT
     muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell);
        console.log("editcell", cell, "column", column, "table", table);
      }
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          // onClick={handleExport}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          size="small"
          tooltip={t("tablelibrary.ttexport2xlsx")}
        >
          {t("tablelibrary.btnexport2xlsx")}
        </Button>
        <Button
          onClick={(el) => {
            downloadPdf(
              data.getTasklistEntryByTasklistId.map((el) => [
                el.id,
                el.creationDate,
                el.createdBy.name,
                el.content,
                el.priority,
                el.duedate,
                el.responsible.name,
                el.recipientname,
                el.status,
                el.lastUpdate,
              ])
            );
          }}
          startIcon={<FileDownloadIcon />}
          variant="contained"
          size="small"
        >
          {t("tablelibrary.btnexport2pdf")}
        </Button>
        <Button
          onClick={() => table.resetColumnFilters()}
          startIcon={<FilterAltOffIcon />}
          variant="contained"
          size="small"
        >
          {t("tablelibrary.tclearfilter")}
        </Button>
      </Box>
    ),
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.1),
        },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.2),
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
        {
          backgroundColor: lighten(baseBackgroundColor, 0.1),
        },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
        {
          backgroundColor: darken(baseBackgroundColor, 0.2),
        },
      }),
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: baseBackgroundColor,
      draggingBorderColor: theme.palette.secondary.main,
    }),
  });

  const handleClose = () => {
    setOpen(false);
  };

 

  //************************ NEW EXPORT CSV **************************************** */
  const exportExcelFile = (dataforexecel) => {
    const workbook = new ExcelJS.Workbook();

    var sheet = workbook.addWorksheet(window.$name, {
      pageSetup: {
        horizontalCentered: true,
        paperSize: 9,
        orientation: "landscape",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    sheet.getRow(1).height = 30;
    sheet.pageSetup.printTitlesColumn = "1:1";
    sheet.autoFilter = {
      from: "A1",
      to: "K1",
    };

    //Define Border of Row 1 Header
    sheet.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
      sheet.getCell(cell.address).border = {
        top: { style: "thick", color: { argb: "FFFF0000" } },
        left: { style: "thick", color: { argb: "00000000" } },
        bottom: { style: "thick", color: { argb: "000000" } },
        right: { style: "thick", color: { argb: "FF00FF00" } },
      };
    });

    const columnsStyle = { font: { name: "Arial Black", size: 10 } };

    //Define Headers Colums and set Backgroundcolor
    let Columsexcel = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
      "K1",
    ];
    Columsexcel.forEach((cellAddress) => {
      const cell = sheet.getCell(cellAddress);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00000000" },
        bgColor: { argb: "96C8FB" },
      };
    });

    //Define fonc option
    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 13,
      bold: true,
      font: { color: { argb: "#FFFFFF" } },
      color: { argb: "FFFFFF" },
    };

    sheet.columns = [
      {
        header: t("tablelibrary.id"),
        key: "id",
        width: 4,
      },
      {
        header: t("tablelibrary.creationdate"),
        key: "creationDate",
        width: 15,
      },
      {
        header: t("tablelibrary.creator"),
        key: "createdByname",
        width: 18,
      },
      {
        header: t("tablelibrary.content"),
        key: "content",
        width: 25,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.source"),
        key: "source",
        width: 20,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.hrl"),
        key: "hrl",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.er"),
        key: "er",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.kr"),
        key: "kr",
        width: 10,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.ek"),
        key: "ek",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.ef"),
        key: "ef",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.lastupdate"),
        key: "lastUpdate",
        width: 15,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
    ];

    // Modify the dataforexecel array to change boolean values to "yes" and "no"
    const modifiedData = dataforexecel.map((element) => ({
      ...element,
      hrl: element.hrl ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
      er: element.er ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
      ek: element.ek ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
      ef: element.ef ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
    }));

  

    modifiedData.forEach((element) => {
      sheet.addRow({
        id: element.id,
        content: element.content,
        creationDate: element.creationDate,
        createdByname: element.createdByname,
        source: element.source,
        hrl: element.hrl,
        er: element.er,
        kr: element.kr,
        ek: element.ek,
        ef: element.ef,
        lastUpdate: element.lastUpdate,
      });
    });

    //Generate the Xlsx file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = window.$name + ".xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  //************************ NEW EXPORT CSV **************************************** */
  const handlecheckchange = (cell_id, journal_id, value_cell, id_content, Refresh) => {
    let valuehrl = null;
    let valueef = null;
    let valueek = null;
    let valueer = null;

    //console.log("HandleCheck", cell_id, journal_id, value_cell, id_content)
    if (value_cell === true) {
      value_cell = false;
    } else {
      value_cell = true;
    }
    const totoggle = cell_id.substring(2);
    // console.log("Variable ", totoggle)
    if (totoggle === "hrl") valuehrl = value_cell;
    if (totoggle === "ef") valueef = value_cell;
    if (totoggle === "er") valueer = value_cell;
    if (totoggle === "ek") valueek = value_cell;

    let vars = {
      id: id_content,
      operation: operation.operation,
      hrl: valuehrl,
      ef: valueef,
      ek: valueek,
      er: valueer,
    };

    modCheck({ variables: vars });
    //console.log("Variable to modify check: ",vars)
  };

  if (errorm) return `Error! ${errorm.message}`;
   if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={80} color="black" loading={loading} />
      </div>
    );
  } 

  if (error) return console.log(`Something get wrong"  ${error.message}`);

  if (data) {
  console.log("Result Query array", data);
   
    // console.log("queryresult", queryresult);

    return (
      <div>
      <Box sx={{ mt: 2, ml: 1, mr: 1 }}>
        <MaterialReactTable table={table} />
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{t("materialreacttable.modal.editcontent")}</DialogTitle>
        <DialogContent>
          <TextField
            label={t("modal.lblcontent")}
            value={selectedContent}
            onChange={(e) => setSelectedContent(e.target.value)}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            variant="filled"
            required
            size="small"
          />
          <Button
              onClick={() => setSelectedContent(t("modal.aeiou"))}
              variant="contained"
              sx={{ width: '20%' }}
              size="small"
            >
              {t("modal.btnaeiou")}
            </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("modal.cancel")}</Button>
          <Button onClick={handleSaveChanges} variant="contained">{t("modal.save")}</Button>
        </DialogActions>
      </Dialog>
    </div>
    );
  }
};

export default GetEntriesTable;
